<template>
    <div v-if="isLoading" class=" flex justify-center items-center w-full h-screen">
        <!-- loading here -->
        <span class=" text-warm-gray-600 italic tracking-wider">Fetching data... Please wait.</span>
    </div>    
    <div v-else class="flex flex-col justify-start items-center w-full pb-10">

        <div class=" flex justify-start items-center w-full">
            <router-link :to="'/reward'" tag="button">
                <ChevronDoubleLeftIcon class=" text-warm-gray-500 font-bold h-6 w-6"/>
            </router-link>
        </div>

        <div v-if="claimantsInfo !== null" class="w-full xl:w-3/4 mt-5">

            <!-- <div v-if="userInfo.isCaapBlocked" class="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md mb-4" role="alert">
                <div class="flex">
                    <div class="py-1">
                        <NoSymbolIcon class="h-6 mr-1 text-red-700"/>
                    </div>
                    <div>
                    <p class="font-bold">User blocked</p>
                    <p v-if="userInfo.caap_data && userInfo.caap_data.expiration_date" class="text-sm">The CAAP subscription expired on {{ formatDate(userInfo.caap_data.expiration_date.seconds) }}.</p>
                    <p v-else class="text-sm">The CAAP subscription expired for this user.</p>
                    </div>
                </div>
            </div> -->

            <div v-if="userInfo.isNotEligibleToClaimAchievementBox" class="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md mb-4" role="alert">
                <div class="flex">
                    <div class="py-1">
                        <NoSymbolIcon class="h-6 mr-1 text-red-700"/>
                    </div>
                    <div>
                    <p class="font-bold">Blacklisted</p>
                    <p class="text-sm"> This user is not eligible to claim the achievement box.</p>
                    </div>
                </div>
            </div>
            
            <h2 class=" text-xl font-semibold tracking-wide mb-4">{{ userInfo.firstname + ' ' + userInfo.lastname }}</h2>
            
            <div class=" leading-6">
                <p class=" text-warm-gray-500 dark:text-warm-gray-400"> <span class=" font-semibold text-warm-gray-600 dark:text-warm-gray-50">Email:</span> {{ userInfo.email }}</p>
                <p class=" text-warm-gray-500 dark:text-warm-gray-400"> <span class=" font-semibold text-warm-gray-600 dark:text-warm-gray-50">School:</span> {{ schoolName(userInfo.school) }}</p>
                <p class=" text-warm-gray-500 dark:text-warm-gray-400"> <span class=" font-semibold text-warm-gray-600 dark:text-warm-gray-50">Course:</span> {{ userInfo.course }}</p>
                <br>
                <p class=" text-warm-gray-500 dark:text-warm-gray-400"> <span class=" font-semibold text-warm-gray-600 dark:text-warm-gray-50">Date Signed Up:</span> {{ formatDate(userInfo.date_created, true) }}</p>
                <p class=" text-warm-gray-500 dark:text-warm-gray-400"> <span class=" font-semibold text-warm-gray-600 dark:text-warm-gray-50">Date Purchase:</span> {{ userInfo.caap_data && userInfo.caap_data.purchase_date ? formatDate(userInfo.caap_data.purchase_date, true) : '---'  }}</p>
                <p class=" text-warm-gray-500 dark:text-warm-gray-400"> <span class=" font-semibold text-warm-gray-600 dark:text-warm-gray-50">Activation Date:</span> {{ userInfo.caap_data && userInfo.caap_data.activation_date ? formatDate(userInfo.caap_data.activation_date.seconds) : '---' }}</p>
                <p class=" text-warm-gray-500 dark:text-warm-gray-400"> <span class=" font-semibold text-warm-gray-600 dark:text-warm-gray-50">Expiration Date:</span> {{ userInfo.caap_data && userInfo.caap_data.expiration_date ? formatDate(userInfo.caap_data.expiration_date.seconds) : '---' }}</p>
                <br>
                <p class=" text-warm-gray-500 dark:text-warm-gray-400"> <span class=" font-semibold text-warm-gray-600 dark:text-warm-gray-50">Facebook Post Date: </span> {{ claimantsInfo.facebookPostDate ? formatDate(claimantsInfo.facebookPostDate, true) : '---' }} </p>
            </div>

            <div class=" flex justify-start items-center mt-5 gap-3">
                <button @click="handleUpdateFbPostDateClick()" class="border border-green-600 py-2 px-4 rounded text-green-600 flex justify-center items-center disabled:opacity-40" :disabled="!isEligible">
                    <CalendarDaysIcon class="h-4 w-4 mr-1"/>
                    <span>Update Post Date</span>
                </button>

                <button @click="openUpdateShippingModal()" class="border border-green-600 py-2 px-4 rounded text-green-600 flex justify-center items-center disabled:opacity-40" :disabled="!isEligible">
                    <TruckIcon class="h-4 w-4 mr-1"/>
                    <span>Update Shipping Details</span>
                </button>

                <button @click="handleForfeitClick()" class="border border-red-600 py-2 px-4 rounded text-red-600 mr-2 flex justify-center items-center disabled:opacity-40" :disabled="claimantsInfo.isForfeited">
                    <NoSymbolIcon class="h-4 w-4 mr-1"/>
                    <span>Forfeit</span>
                </button>

            </div>

            <!-- post exam survey -->
            <div class=" mt-10 border rounded-md border-warm-gray-400 bg-warm-gray-300 dark:border-warm-gray-600 dark:bg-warm-gray-800 p-4">

                <h3 class=" py-2 border-b border-warm-gray-400 dark:border-warm-gray-600 flex justify-start">
                    <span class="mr-2 font-semibold tracking-wide uppercase">Post Exam Survey</span>
                    <span v-if="postExamSurvey.status == 'submitted'" class="rounded-full bg-warm-gray-500 text-white px-2 text-xs py-0.5 capitalize">{{ postExamSurvey.status }}</span>
                    <span v-else-if="postExamSurvey.status == 'rejected'" class="rounded-full bg-red-600 text-white px-2 text-xs py-0.5 capitalize">{{ postExamSurvey.status }}</span>
                    <span v-else-if="postExamSurvey.status == 'approved'" class="rounded-full bg-green-600 text-white px-2 text-xs py-0.5 capitalize">{{ postExamSurvey.status }}</span>
                    <span v-else class="rounded-full bg-indigo-500 text-white px-2 text-xs py-0.5 capitalize">{{ postExamSurvey.status }}</span>
                </h3>

                <table class=" mt-5 w-full">
                    <tr class="">
                        <td class=" w-44 align-text-top">
                            Uploaded Photo:
                        </td>
                        <td class=" hidden md:table-cell">
                            <a :href="postExamSurvey.userPhotoUrl" target="_blank">
                                <img :src="postExamSurvey.userPhotoUrl" alt="Uploaded user photo" class=" h-20">
                            </a>
                        </td>
                    </tr>
                    <tr class=" h-3"></tr>
                    <tr class="">
                        <td class=" w-44 align-text-top">
                            Uploaded result URL:
                            <p class="md:hidden">
                                <a :href="postExamSurvey.resultsUrl" target="_blank" class="text-blue-600 dark:text-blue-400 hover:underline">
                                    {{ postExamSurvey.resultsUrl }}
                                </a>
                            </p>
                        </td>
                        <td class=" hidden md:table-cell">
                            <a :href="postExamSurvey.resultsUrl" target="_blank" class="text-blue-600 dark:text-blue-400 hover:underline">
                                {{ postExamSurvey.resultsUrl }}
                            </a>
                        </td>
                    </tr>
                    <tr class=" h-3"></tr>
                    <tr class="">
                        <td class=" w-44 align-text-top">
                            Uploaded KTRs:
                            <p class="md:hidden mt-2 space-x-2">
                                <a v-for="ktr,i in userKtrs" :href="postExamSurvey.resultsUrl" target="_blank" class=" border rounded-full px-2 py-0.5 bg-warm-gray-500">
                                    KTR-{{ i + 1 }}
                                </a>
                            </p>
                        </td>
                        <td class=" hidden md:table-cell space-x-2">
                            <a v-for="ktr,i in userKtrs" :href="ktr.url" target="_blank" class=" border rounded-full px-2 py-0.5 bg-warm-gray-500">
                                KTR-{{ i + 1 }}
                            </a>
                        </td>
                    </tr>
                    <tr class=" h-3"></tr>
                    <tr>
                        <td class=" align-text-top">Inspiring comments:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">{{ postExamSurvey.inspiringComment }}</td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Platform feedback:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">{{ postExamSurvey.platformFeedback }}</td>
                    </tr>
                    <!-- Scorecard -->
                    <tr class=" h-8"><td></td></tr>
                    <tr>
                        <td colspan="2" class="text-warm-gray-500 tracking-wide font-semibold uppercase">
                            Scorecard ({{ userInfo.examTaken }})
                        </td>
                    </tr>
                    <tr class=" h-2"><td></td></tr>
                    <tr>
                        <td colspan="2">

                            <table class="w-full">
                                <thead>
                                    <tr class="border-t border-b border-warm-gray-600 dark:border-warm-gray-300">
                                        <th class="text-left py-1">Subject</th>
                                        <th class="text-center">Score</th>
                                        <th class="text-center">Take</th>
                                        <th class="text-center">items</th>
                                        <th class="text-center">Exam Date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="result,i in userScorecard" :key="i" class="border-b border-dashed border-warm-gray-400 dark:border-warm-gray-600 text-warm-gray-500 dark:text-warm-gray-400">
                                        <td class="text-left py-1">{{ result.data.subjectId }}</td>
                                        <td class="text-center" :class="[result.data.isPassed ? 'text-green-700':'text-red-700']">
                                            {{result.data.score}}%
                                            <span v-if="result.data.isChangePassingScore">🚩</span>
                                        </td>
                                        <td class="text-center">{{ getOrdinal(result.data.takeCount) }}</td>
                                        <td class="text-center">
                                            {{ result.data.numItemsFromApp }} / {{ result.data.numItemsInExam }} 
                                            <span v-if="result.data.isChangeItemCount">🚩</span>
                                        </td>
                                        <td class="text-center">{{ formatDate(result.data.examDate.seconds) }}</td>
                                        <td class="flex justify-end items-center">
                                            <button 
                                                @click="openAddedQuestionModal(result.data.addedQuestion)"
                                                class=" text-blue-600 p-1 disabled:opacity-20" 
                                                :disabled="result.data.addedQuestion.length == 0">
                                                <DocumentPlusIcon class="h-5"/>
                                            </button>
                                            <button class=" text-yellow-600 p-1" @click="openCommentsModal(result.data.comments)">
                                                <ChatBubbleBottomCenterTextIcon class="h-5"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                                                        
                        </td>
                    </tr>

                    <!-- Other details -->
                    <tr class=" h-8"><td></td></tr>
                    <tr>
                        <td colspan="2" class="text-warm-gray-500 tracking-wide font-semibold uppercase">
                            Other Details
                        </td>
                    </tr>
                    <tr class=" h-2"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Submitted on:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">{{ formatDate(postExamSurvey.metadata.submittedOn, true) }}</td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Updated on:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">{{ formatDate(postExamSurvey.metadata.updatedOn, true) }}</td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Approved on:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">{{ postExamSurvey.metadata.approvedOn ? formatDate(postExamSurvey.metadata.approvedOn, true) : '---' }}</td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Approved by:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">{{ postExamSurvey.metadata.approvedBy ? postExamSurvey.metadata.approvedBy : '---' }}</td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Rejected:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400"> {{ postExamSurvey.metadata.rejected ? postExamSurvey.metadata.rejected : 0 }}</td>
                    </tr>
                </table>

                <div class="w-full flex justify-end items-center">
                    <button @click="openRejectReasonModal('post-exam-survey')" class="border border-red-600 py-2 px-4 rounded text-red-600 mr-2 flex justify-center items-center disabled:opacity-40" :disabled="postExamSurvey.status !== 'submitted' || userInfo.isNotEligibleToClaimAchievementBox">
                        <XMarkIcon class="h-4 w-4"/>
                        <span>Reject</span>
                    </button>
                    <button @click="aprvPostExamSurvey()" class="border border-green-600 py-2 px-4 rounded text-green-600 flex justify-center items-center disabled:opacity-40" :disabled="postExamSurvey.status !== 'submitted' || userInfo.isNotEligibleToClaimAchievementBox">
                        <CheckIcon class="h-4 w-4"/>
                        <span>Approve</span>
                    </button>
                </div>

            </div>

            <!-- achievement box -->
            <div class=" mt-10 border rounded-md border-warm-gray-400 bg-warm-gray-300 dark:border-warm-gray-600 dark:bg-warm-gray-800 p-4">
                
                <h3 class=" py-2 border-b border-warm-gray-400 dark:border-warm-gray-600 flex justify-start">
                    <span class="mr-2 font-semibold tracking-wide uppercase">Achievement Box</span>
                    <span v-if="achievementBox.status == 'submitted'" class="rounded-full bg-warm-gray-500 text-white px-2 text-xs py-0.5 capitalize">{{ achievementBox.status }}</span>
                    <span v-else-if="achievementBox.status == 'rejected'" class="rounded-full bg-red-600 text-white px-2 text-xs py-0.5 capitalize">{{ achievementBox.status }}</span>
                    <span v-else-if="achievementBox.status == 'approved'" class="rounded-full bg-green-600 text-white px-2 text-xs py-0.5 capitalize">{{ achievementBox.status }}</span>
                    <span v-else class="rounded-full bg-indigo-500 text-white px-2 text-xs py-0.5 capitalize">{{ achievementBox.status }}</span>
                </h3>

                <table class=" mt-5">
                    <tr>
                        <td class=" w-44">PEL number:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">
                            {{ achievementBox.pelNum }}
                        </td>
                    </tr>
                    <tr class=" h-8"></tr>
                    <tr>
                        <td colspan="2" class="text-warm-gray-600 dark:text-warm-gray-500 tracking-wide font-semibold uppercase">
                            Shipping details
                        </td>
                    </tr>
                    <tr class=" h-2"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Province:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">{{ achievementBox.shippingAddress.province }}</td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">City / Municipality:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">{{ achievementBox.shippingAddress.city }}</td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Barangay:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">{{ achievementBox.shippingAddress.barangay }}</td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">House No./Bldg/Street:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">{{ achievementBox.shippingAddress.addressLine1 }}</td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Zip code:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">{{ achievementBox.shippingAddress.zip }}</td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Complete address:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">{{ achievementBox.shippingAddress.addressLine2 }}</td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Contact number:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">{{ achievementBox.phone }}</td>
                    </tr>
                    <tr class=" h-7"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Tracking number:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">{{ achievementBox.trackingNum ? achievementBox.trackingNum : '' }}</td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Tracking URL:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">
                            <a v-if="achievementBox.trackingUrl" :href="achievementBox.trackingUrl" target="_blank" class=" text-blue-400 hover:underline">
                                {{ achievementBox.trackingUrl }}
                            </a>
                            <span v-else class="text-warm-gray-600">---</span>
                        </td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Courier name:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">
                            {{ achievementBox.courierName ? achievementBox.courierName : '---'}}
                        </td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Shipped on:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">
                            {{ achievementBox.metadata.shippedOn ? formatDate(achievementBox.metadata.shippedOn, true) : '---' }}
                        </td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Shipped by:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">
                            {{ achievementBox.metadata.shippedBy ? achievementBox.metadata.shippedBy : '---' }}
                        </td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Received on:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">
                            {{ achievementBox.metadata.receivedOn ? formatDate(achievementBox.metadata.receivedOn, true) : '---' }}
                        </td>
                    </tr>

                    <tr class=" h-8"><td></td></tr>
                    <tr>
                        <td colspan="2" class="text-warm-gray-600 dark:text-warm-gray-500 tracking-wide font-semibold uppercase">
                            Other Details
                        </td>
                    </tr>
                    <tr class=" h-2"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Submitted on:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">
                            {{ formatDate(achievementBox.metadata.submittedOn, true) }}
                        </td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Updated on:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">
                            {{ formatDate(achievementBox.metadata.updatedOn, true) }}
                        </td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Approved on:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">
                            {{ achievementBox.metadata.approvedOn ? formatDate(achievementBox.metadata.approvedOn, true) : '---' }}
                        </td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Approved by:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">
                            {{ achievementBox.metadata.approvedBy ? achievementBox.metadata.approvedBy : '---' }}
                        </td>
                    </tr>
                    <tr class=" h-3"><td></td></tr>
                    <tr>
                        <td class=" align-text-top">Rejected:</td>
                        <td class=" text-warm-gray-500 dark:text-warm-gray-400">
                            {{ achievementBox.metadata.rejected ? achievementBox.metadata.rejected : 0 }}
                        </td>
                    </tr>
                </table>

                <div class="w-full flex justify-end items-center">

                    <button @click="openRejectReasonModal('achievement-box')" class="border border-red-600 py-2 px-4 rounded text-red-600 mr-2 flex justify-center items-center disabled:opacity-40" :disabled="achievementBox.status !== 'submitted' || userInfo.isNotEligibleToClaimAchievementBox">
                        <XMarkIcon class="h-4 w-4"/>
                        <span>Reject</span>
                    </button>
                    <button @click="aprvAchievementBox()" class="border border-green-600 py-2 px-4 rounded text-green-600 flex justify-center items-center disabled:opacity-40" :disabled="achievementBox.status !== 'submitted' || userInfo.isNotEligibleToClaimAchievementBox">
                        <CheckIcon class="h-4 w-4"/>
                        <span>Approve</span>
                    </button>

                </div>

            </div>

        </div>

        <div v-else>
            <h2 class=" text-warm-gray-600">
                User not found!
            </h2>
        </div>
    </div>

    <!-- Update Shipping Modal -->
    <TransitionRoot appear :show="isUpdateShippingModalOpen" as="template">
        <Dialog as="div" @close="closeUpdateShippingModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">

            <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40 dark:bg-warm-gray-400" />

            <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
            </span>

            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
                >
                <div
                class="inline-block w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-800"
                >
                <DialogTitle
                    as="h3"
                    class="flex items-center text-lg font-medium leading-6 text-white bg-yellow-600 p-6 pb-2"
                >
                Update shipping details
                </DialogTitle>
                <div class="mt-2 flex-row items-start text-warm-gray-700 p-6 dark:text-warm-gray-200">
                    <div class="w-full">
                    <label for="courier-name"> <span class=" text-red-600">*</span> Courier Name:</label>
                    <input id="courier-name" 
                        v-model="shippingInfo.courierName"
                        class="disabled:cursor-wait disabled:opacity-25 mb-2 w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" placeholder="Enter courier name" />
                    </div>
                    <div class="w-full">
                    <label for="tracking-num"> <span class=" text-red-600">*</span> Tracking Number:</label>
                    <input id="tracking-num" 
                        v-model="shippingInfo.trackingNum"
                        class="disabled:cursor-wait disabled:opacity-25 mb-2 w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" 
                        placeholder="Enter tracking number" />
                    </div>
                    <div class="w-full">
                    <label for="tracking-url">Tracking URL:</label>
                    <input id="tracking-url" 
                        v-model="shippingInfo.trackingUrl"
                        class="disabled:cursor-wait disabled:opacity-25 mb-2 w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" 
                        placeholder="Enter tracking URL" />
                    </div>
                    <div class="w-full">
                    <label for="shipping-date"> <span class=" text-red-600">*</span> Shipping Date:</label>
                    <input type="date" id="shipping-date" 
                        v-model="shippingInfo.shippedOn"
                        class="disabled:cursor-wait disabled:opacity-25 mb-2 w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" 
                        placeholder="Enter shipping date" />
                    </div>
                    <div class="w-full">
                    <label for="shipped-by"> <span class=" text-red-600">*</span> Shipped By:</label>
                    <input id="shipped-by" 
                        v-model="shippingInfo.shippedBy"
                        class="disabled:cursor-wait disabled:opacity-25 mb-2 w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" 
                        placeholder="Enter complete name" />
                    </div>

                </div>

                <div class="mt-4 border-t p-6 pt-3 flex justify-between dark:border-warm-gray-600">
                    <div class=" flex justify-start items-end">
                        <!-- <router-link :to="`/v2/user/${user.id}`" class="focus:outline-none"> -->
                        <!-- <span class=" text-yellow-600">
                            {{ isRetrievingAchievementBoxInfo ? 'Retrieving shipping info....' : '' }}
                        </span> -->
                        <span v-if="achievementBox.isEmailSent != null && achievementBox.isEmailSent" class=" text-warm-gray-400 dark:text-warm-gray-600">Email sent</span>
                        <button
                            v-else
                            type="button"
                            class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-500 border border-warm-gray-400 rounded hover:bg-warm-gray-50 dark:hover:bg-warm-gray-900 disabled:opacity-40"
                            @click="sendEmail"
                            :disabled="isSendingEmail"
                            >
                            <span v-if="!isSendingEmail">Send Email</span>
                            <span v-else>Sending...</span>
                        </button>
                    </div>
                    <div>
                    <button
                        type="button"
                        class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600 dark:text-warm-gray-200"
                        @click="closeUpdateShippingModal"
                        >
                        Cancel
                    </button>

                    <button
                        type="button"
                        class="disabled:cursor-not-allowed disabled:opacity-25 text-warm-gray-100 bg-red-600 hover:bg-red-800 focus:bg-red-800 dark:bg-red-700 dark:hover:bg-red-600 dark:focus:bg-red-600 dark:text-warm-gray-200 ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        @click="updtShippingInfo"
                        :disabled="isUpdatingShippingInfo || achievementBox.status == 'received' || !isCanUpdate()"
                        >
                        <SpinningIcon class="h-4 w-4 mr-1" v-if="isUpdatingShippingInfo" />
                        Update
                    </button>
                    </div>
                </div>
                </div>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>

    <!-- Add Reject Reason Modal -->
    <TransitionRoot appear :show="isRejectReasonModalOpen" as="template">
        <Dialog as="div" @close="closeRejectReasonModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">

            <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40 dark:bg-warm-gray-400" />

            <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
            </span>

            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
                >
                <div
                class="inline-block w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-800"
                >
                <div class="mt-2 flex-row items-start text-warm-gray-700 p-6 dark:text-warm-gray-200">
                    
                    <h3 class=" tracking-wide font-semibold text-2xl mb-4">Reason</h3>

                    <p class=" text-warm-gray-400">Please provide the reason for rejecting the user's <span class=" text-blue-600">{{ formToReject == 'post-exam-survey' ? 'post exam survey' : 'achievement box' }}</span>.</p>
                    <textarea v-model="rejectReason" name="reject-reason" id="reject-reason" rows="3" class=" w-full mt-2 rounded-md p-2 text-warm-gray-800"></textarea>

                    <div class=" flex justify-end items-center mt-3">
                        <button @click="closeRejectReasonModal()" class="border border-warm-gray-500 text-warm-gray-500 py-1 px-2 rounded mr-2">Cancel</button>
                        <button v-if="formToReject == 'post-exam-survey'" @click="rejPostExamSurvey()" class="border border-red-500 text-red-500 py-1 px-2 rounded disabled:opacity-50" :disabled="rejectReason.trim() == ''">Reject</button>
                        <button v-else @click="rejAchievementBox()" class="border border-red-500 text-red-500 py-1 px-2 rounded disabled:opacity-50" :disabled="rejectReason.trim() == ''">Reject</button>
                    </div>
                </div>

               
                </div>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>

     <!-- Comments Modal -->
    <TransitionRoot as="template" id="test1" :show="isCommentsModalOpen">
        <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="closeCommentsModal()">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 dark:bg-warm-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
            </span>
            
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div class="inline-block align-bottom dark:bg-warm-gray-800  bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <!-- Close Button -->
                <div class="block absolute top-0 right-0 pt-4 pr-4">
                    <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="closeCommentsModal()">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                
                
                <div class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-4 mt-4">

                    <!-- <span class="block text-base text-center dark:text-yellow-300 text-yellow-600 font-semibold tracking-wide uppercase mb-10">
                        Subject Name here sd
                    </span> -->

                    <h2 class="mb-6 font-semibold tracking-wider text-xl text-warm-gray-700 dark:text-white">Comments</h2>
                    
                    <p class=" text-warm-gray-500 dark:text-warm-gray-400">{{ openComments }}</p>

                    
                </div>
                </div>
            </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <!-- Added Question Modal -->
    <TransitionRoot as="template" id="test1" :show="isAddedQuestionModalOpen">
        <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="closeAddedQuestionModal()">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 dark:bg-warm-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
            </span>
            
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div class="inline-block align-bottom dark:bg-warm-gray-800  bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <!-- Close Button -->
                <div class="block absolute top-0 right-0 pt-4 pr-4">
                    <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="closeAddedQuestionModal()">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                
                
                <div v-if="openAddedQuestion.length > 0" class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-4 mt-4">

                    <!-- <span class="block text-base text-center dark:text-yellow-300 text-yellow-600 font-semibold tracking-wide uppercase mb-10">
                        Subject Name here sd
                    </span> -->

                    <h2 class="mb-6 font-semibold tracking-wider text-xl text-warm-gray-600 dark:text-white">Added Question {{ openAddedQuestionIndex + 1}}</h2>

                    <div class=" text-warm-gray-500 dark:text-warm-gray-400">
                        
                        <p>
                            {{ openAddedQuestion[openAddedQuestionIndex].question }}
                        </p>

                        <p class="text-warm-gray-600 dark:text-warm-gray-200 font-semibold tracking-wide mt-8">Choices:</p>

                        <ul>
                            <li v-for="choice in openAddedQuestion[openAddedQuestionIndex].choices" class=" bg-warm-gray-300 dark:bg-warm-gray-900 bg-opacity-30 border border-dashed border-warm-gray-700 my-2 p-2 rounded">
                                {{ choice }}
                            </li>
                        </ul>

                        <div class="flex justify-end mt-8 gap-2">
                            <button @click="openAddedQuestionIndex--" class=" py-1 px-3 border rounded border-warm-gray-500 text-warm-gray-500 flex justify-center items-center disabled:opacity-30" :disabled="openAddedQuestionIndex == 0">
                                <ChevronLeftIcon class=" h-5 w-5"/>
                                <span class="mb-0.5">Prev</span>
                            </button>
                            <button @click="openAddedQuestionIndex++" class=" py-1 px-3 border rounded border-warm-gray-500 text-warm-gray-500 flex justify-center items-center disabled:opacity-30" :disabled="openAddedQuestionIndex == (openAddedQuestion.length - 1)">
                                <span class="mb-0.5">Next</span>
                                <ChevronRightIcon class=" h-5 w-5"/>
                            </button>
                        </div>
                    </div>
                    
                    
                </div>
                </div>
            </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <!-- Update Facebook Posted Date Modal -->
    <TransitionRoot appear :show="isUpdateFbPostedDateModalOpen" as="template">
        <Dialog as="div" @close="isUpdateFbPostedDateModalOpen = false">
        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">

            <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40 dark:bg-warm-gray-400" />

            <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
            </span>

            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
                >
                <div
                class="inline-block w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-800"
                >
                <div class="mt-2 flex-row items-start text-warm-gray-700 p-6 dark:text-warm-gray-200">
                    
                    <h3 class=" tracking-wide font-semibold text-2xl mb-4">Social Media Post Date</h3>

                    <input type="date" id="shipping-date" 
                        v-model="fbPostDate"
                        class="disabled:cursor-wait disabled:opacity-25 mb-2 w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" 
                        placeholder="Enter facebook post date" />

                    <div class=" flex justify-end items-center mt-3">
                        <button @click="isUpdateFbPostedDateModalOpen = false" class="border border-warm-gray-500 text-warm-gray-500 py-1 px-2 rounded mr-2">Cancel</button>
                        <button @click="handleUpdateFacebookPostDate" class="border border-red-500 text-red-500 py-1 px-2 rounded disabled:opacity-50" :disabled="fbPostDate.trim() == ''">Update</button>
                    </div>
                </div>

               
                </div>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>

</template>

<script setup>

import { httpsCallable } from "firebase/functions";
import { functions } from '@/firebase/config.js';

import { XMarkIcon, ChevronLeftIcon, ChevronRightIcon, CheckIcon, ChevronDoubleLeftIcon, TruckIcon, ChatBubbleBottomCenterTextIcon, DocumentPlusIcon, NoSymbolIcon, CalendarDaysIcon } from '@heroicons/vue/24/outline'
import {ref, onMounted, watch } from 'vue'
import {useRoute} from 'vue-router'
import SpinningIcon from '@/components/SpinningIcon';

import swal from 'sweetalert2'; 

import {
  // modal
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue';

// firebase
import { 
    getClaimantsInfo, 
    getUserInfo,
    fetchSchools, 
    getPostExamSurvey,
    getAchievementBox,
    rejectAchievementBox,
    approveAchievementBox,
    rejectPostExamSurvey,
    approvePostExamSurvey,
    updateShippingInfo,
    fetchUserScorecard,
    fetchUserKtrs,
    sentEmailUpdateInfo,
    forfeitClaimants,
    updateFacebookPostDate
} from '../firebase';

const route = useRoute()

const isSendingEmail = ref(false);
const isEligible = ref(false);
const isLoading = ref(false);
const userInfo = ref(null);
const claimantsInfo = ref(null);
const schools = ref(null);
const postExamSurvey = ref(null);
const achievementBox = ref(null);
const userScorecard = ref([]);
const isUpdatingShippingInfo = ref(false)
const rejectReason = ref('');
const formToReject = ref(null);
const userKtrs = ref([]);

const shippingInfo = ref({
    courierName: '',
    trackingNum: '',
    trackingUrl: '',
    shippedOn: '',
    shippedBy: ''
});

const isUpdateShippingModalOpen = ref(false);
const isRejectReasonModalOpen = ref(false);

const checkIfEligible = () => {
    console.log('check if eligible');
    isEligible.value =  postExamSurvey.value.status == 'approved' && ['approved','shipped','received'].includes(achievementBox.value.status);
}

const isCanUpdate = () => {
    return shippingInfo.value.courierName && shippingInfo.value.trackingNum && shippingInfo.value.shippedOn && shippingInfo.value.shippedBy
}

const openUpdateShippingModal = () => {
    isUpdateShippingModalOpen.value = true;

    const ab = {...achievementBox.value}

    shippingInfo.value.courierName = ab.courierName ? ab.courierName : 'J&T Express';
    shippingInfo.value.trackingNum = ab.trackingNum ? ab.trackingNum : '';
    shippingInfo.value.trackingUrl = ab.trackingUrl ? ab.trackingUrl : 'https://www.jtexpress.ph/trajectoryQuery';
    shippingInfo.value.shippedOn = ab.metadata.shippedOn ? new Date( parseInt(ab.metadata.shippedOn) ).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]; 
    shippingInfo.value.shippedBy = ab.metadata.shippedBy ? ab.metadata.shippedBy : 'John Denver Abrea';

}

const closeUpdateShippingModal = () => {
    isUpdateShippingModalOpen.value = false;

    shippingInfo.value.courierName = '';
    shippingInfo.value.trackingNum = '';
    shippingInfo.value.trackingUrl = '';
    shippingInfo.value.shippedOn = '';
    shippingInfo.value.shippedBy = ''

}

const openRejectReasonModal = (formType) => {
    formToReject.value = formType;
    isRejectReasonModalOpen.value = true;
}

const closeRejectReasonModal = () => {
    isRejectReasonModalOpen.value = false;
    formToReject.value = null;
    rejectReason.value = '';
}

// comment modal
const isCommentsModalOpen = ref(false);
const openComments = ref('')

const openCommentsModal = (comment) => {
    isCommentsModalOpen.value = true;
    openComments.value = comment
}

const closeCommentsModal = () => {
    isCommentsModalOpen.value = false;
    openComments.value = ''
}


// Added question modala
const isAddedQuestionModalOpen = ref(false)
const openAddedQuestion = ref([])
const openAddedQuestionIndex = ref(0)

const closeAddedQuestionModal = () => {
    isAddedQuestionModalOpen.value = false;
    openAddedQuestion.value = []
    openAddedQuestionIndex.value = 0
}

const openAddedQuestionModal = (addedQuestion) => {
    isAddedQuestionModalOpen.value = true;
    openAddedQuestion.value = addedQuestion
}


const schoolName = (schoolId) => {
    return schools.value[schoolId] ? schools.value[schoolId].name : '---';
}

const rejPostExamSurvey = async () => {
    await rejectPostExamSurvey(route.params.userId, rejectReason.value)
    postExamSurvey.value = await getPostExamSurvey(route.params.userId)
    closeRejectReasonModal()
}

const aprvPostExamSurvey = async () => {
    await approvePostExamSurvey(route.params.userId)
    postExamSurvey.value = await getPostExamSurvey(route.params.userId)
}

const rejAchievementBox = async () => {
    await rejectAchievementBox(route.params.userId, rejectReason.value)
    achievementBox.value = await getAchievementBox(route.params.userId)
    closeRejectReasonModal()
}

const aprvAchievementBox = async () => {
    await approveAchievementBox(route.params.userId)
    achievementBox.value = await getAchievementBox(route.params.userId)
}

const updtShippingInfo = async () => {
    isUpdatingShippingInfo.value = true;

    await updateShippingInfo(route.params.userId, shippingInfo.value)
    achievementBox.value = await getAchievementBox(route.params.userId)

    isUpdatingShippingInfo.value = false;
    isUpdateShippingModalOpen.value = false;
}

const sendEmail = async () => {

    if (achievementBox.value.trackingNum) {

        isSendingEmail.value = true;
        const sendEmailFbFunc = httpsCallable(functions, "sendEmailShippedABox");
        const sendEmail = await sendEmailFbFunc({
            email: userInfo.value.email,
            name: userInfo.value.firstname,
            trackingNum: achievementBox.value.trackingNum,
        });

        await sentEmailUpdateInfo(route.params.userId);
        achievementBox.value = await getAchievementBox(route.params.userId)

        isSendingEmail.value = false;
    }else{
        alert('Tracking number is required.')
    }

}

const handleForfeitClick = async () => {
    const result = await swal.fire({
        title: "Are you sure?",
        text: "This will forfeit the claim permanently.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, forfeit it!"
    });

    if (result.isConfirmed) {
        await forfeitClaimants(route.params.userId)
        swal.fire("Forfeited!", "The claim has been forfeited.", "success");
    }
}

const isUpdateFbPostedDateModalOpen = ref(false);
const fbPostDate = ref('');
const handleUpdateFbPostDateClick = async () => {
    fbPostDate.value = claimantsInfo.value.facebookPostDate ? new Date( parseInt(claimantsInfo.value.facebookPostDate) ).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]; 
    isUpdateFbPostedDateModalOpen.value = true;
}

const handleUpdateFacebookPostDate = async () => {
    await updateFacebookPostDate(route.params.userId, fbPostDate.value);
    claimantsInfo.value = await getClaimantsInfo(route.params.userId);
    isUpdateFbPostedDateModalOpen.value = false;
    swal.fire("Update!", "The post date has been updated.", "success");
}

watch(postExamSurvey, (newVal, oldVal) => {
    checkIfEligible()
})

watch(achievementBox, (newVal, oldVal) => {
    checkIfEligible()
})

onMounted(async ()=>{

    isLoading.value = true
    // get the claimants information
    // if not found then reroute back to reward page
    // else get the user information, achievement box and post exam survey information
    claimantsInfo.value = await getClaimantsInfo(route.params.userId);
     
    if (claimantsInfo.value === null) {
        isLoading.value = false
        return;
    }

    const [retUserInfo, retSchools, retPostExamSurvey, retAchievementBox, retUserScorecard, retUserKtrs] = await Promise.all([
        getUserInfo(route.params.userId),
        fetchSchools(true), // true = return in object
        getPostExamSurvey(route.params.userId),
        getAchievementBox(route.params.userId),
        fetchUserScorecard(route.params.userId),
        fetchUserKtrs(route.params.userId)
    ]);

    userInfo.value = retUserInfo;
    schools.value = retSchools;
    postExamSurvey.value = retPostExamSurvey;
    achievementBox.value = retAchievementBox;
    userKtrs.value = retUserKtrs;

    // rearrange the subject to display based on the test result template
    userScorecard.value = retUserScorecard;
    const order = [
            'air-law',
            'nat-sci',
            'aircraft-engineering',
            'aircraft-maintenance',
            'human-performance',
            'airframe-rating',
            'powerplant-rating',
            'avionics-rating'
            ];
    userScorecard.value.sort((a, b) => {
        let indexA = order.indexOf(a.data.subjectId);
        let indexB = order.indexOf(b.data.subjectId);

        // If the subjects are the same, compare by takeCount
        if (indexA === indexB) {
            return a.data.takeCount - b.data.takeCount;
        }

        return indexA - indexB; // Sort by subjectId
    });

    isLoading.value = false    

})


// helper functions
const formatDate = (timestampInSeconds, isFromMoment = false) => {

    let timestampInMilliseconds;
    if (isFromMoment) {
        timestampInMilliseconds = parseInt(timestampInSeconds);
    }else{
        timestampInMilliseconds = timestampInSeconds * 1000;
    }
    const date = new Date(timestampInMilliseconds);

    // Define an array to map month numbers to month names
    const months = [
    "Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.",
    "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
    ];

    // Get the month, day, and year from the date object
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    // Construct the formatted date string
    return `${month} ${day}, ${year}`;

}

const getOrdinal = (number) => {
    // Handle special cases for 11th, 12th, and 13th
    if (number % 100 >= 11 && number % 100 <= 13) {
        return number + "th";
    }

    // Handle all other cases
    switch (number % 10) {
        case 1:
            return number + "st";
        case 2:
            return number + "nd";
        case 3:
            return number + "rd";
        default:
            return number + "th";
    }
}


</script>